import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import Image from 'next/image';

interface IPost {
  posts: {
    databaseId: number;
    slug: string;
    title: string;
    content: string;
    date: string;
    featuredImage: {
      node: {
        sourceUrl: string;
      };
    };
  }[];
}

export const News = ({ posts }: IPost) => {
  const { t, lang } = useTranslation('common');
  return (
    <div className="bg-gray-50 pt-4">
      <div className="text-center text-3xl">{t('news')}</div>
      <div className="max-w-7xl mx-auto py-6 lg:py-8 lg:px-8 flex flex-wrap justify-center">
        {posts.map((post) => {
          const publishedDate = new Date(post.date).toLocaleString('pl-PL');
          const descriptionAll = post.content.replace(
            /(&nbsp;|&#8211;|(<([^>]+)>))/gi,
            ''
          );
          const description = descriptionAll
            .split(/\s+/)
            .slice(0, 20)
            .join(' ');
          return (
            <div
              key={post.databaseId}
              className="max-w-sm min-h-full bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 m-1"
            >
              <Link href={`news/${post.slug}`}>
                <Image
                  src={post.featuredImage?.node.sourceUrl}
                  alt={post.title}
                  width="954"
                  height="500"
                  className="rounded-t-lg"
                />
              </Link>
              <time className="text-white text-sm pl-5">{publishedDate}</time>
              <div className="px-5 pt-2 pb-4">
                <Link href={`news/${post.slug}`}>
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {post.title}
                  </h5>
                </Link>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  {description} ...
                </p>
                <a
                  href={`${lang !== 'en' ? lang : ''}/news/${post.slug}`}
                  className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Czytaj więcej
                  <svg
                    aria-hidden="true"
                    className="ml-2 -mr-1 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
